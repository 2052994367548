.logo {
  max-width: 160px;
  margin-left: 20px;
}
.loginButton {
  float: right;
}
.activeButton {
  text-decoration: underline !important;
}
